:root {
  --display-color: #303030;
}

.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
  background-color: #fdfdfd;
}

.App-header {

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
